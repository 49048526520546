import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { DateTime } from "luxon";

function ProgressBar({ currentStep, stepClicked, stepsCustom, cancelled, cancelledDateTime }: any) {
  var steps = ["Company Details", "Personal Details", "Review", "Submitted"];
  if (stepsCustom) {
    steps = stepsCustom;
  }
  const progressValue = (currentStep / (steps.length - 1)) * 100;

  useEffect(() => {
    console.log(currentStep, stepsCustom);
  }, [currentStep]);

  return (
    <div className={`md:px-16  pt-2 pb-4 `}>
      {cancelled ? (
        <div className="flex flex-col items-center justify-start">
          <img src={require("../../assets/cancel.png")} className="w-20 h-20 mb-4" />
          <p className="text-2xl font-bold">
            CANCELLED ON {DateTime.fromISO(cancelledDateTime).toFormat("dd LLL yyyy @ hh:mm a ")}
          </p>
          <p>This application had been cancelled.</p>
        </div>
      ) : (
        <Box sx={{ position: "relative", marginBottom: "15px" }}>
          <LinearProgress
            variant="determinate"
            value={progressValue}
            sx={{
              backgroundColor: "#EEEEEE",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "green", // Set the progress bar color
              },
              [`& .${linearProgressClasses.bar}`]: {
                // borderRadius: 5,
                backgroundColor: "#555",
              },
            }} // Set the progress bar color
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="absolute"
            width="100%"
            top="1"
            height="100%"
          >
            {steps.map((step, index) => (
              <div
                key={step}
                onClick={() => {
                  stepClicked(index + 1);
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "absolute",
                  left: `${(index / (steps.length - 1)) * 100}%`,
                  top: "-18px",
                  transform: `translateX(-50%)`,
                }}
              >
                <Box
                  width={32}
                  height={32}
                  borderRadius="50%"
                  bgcolor={"#86eb85"}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  zIndex={1}
                  border={index == currentStep ? 3 : 0}
                  borderColor={"#555555"}
                >
                  <p className={`text-primary font-semibold`}>{index + 1}</p>
                </Box>
                {index == currentStep ? (
                  <p className="text-xs md:text-base font-semibold my-1 break-words w-24 text-center">{step}</p>
                ) : (
                  <p className="text-center text-xs my-1 md:text-base w-20">{step}</p>
                )}
              </div>
            ))}
          </Box>
        </Box>
      )}
    </div>
  );
}

export default ProgressBar;
