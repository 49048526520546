import React, { useState, useMemo, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import ReactDOMServer from "react-dom/server";
import { toast } from "react-toastify";
import { FilterList, KeyboardArrowDown } from "@mui/icons-material";
import DateRangePicker from "../DateRangePicker";
import SearchBox from "../SearchBox";
import { DateTime } from "luxon";
import "./DynamicTable.css";

interface DynamicTableProps {
  tableId?: string; // Add tableId as a prop
  cols: any[];
  rows: any[];
  heading?: string;
  classNames?: string[];
  onRowClicked: any;
  loading: boolean;
  smallHeader?: boolean;
  idIndexInColumns: number;
  showID: boolean;
  searchKeys?: string[];
  searchQueryInput?: string;
  setSearchQueryInput?: (value: string) => void;
  rowClasses?: string;
  filterableColumns?: string[]; // Add this prop for filterable columns
  showExport?: boolean;
  onFiltered?: any;
  rowExpansionComponents?: any[];
}

interface DateFilter {
  startDate: DateTime | null;
  endDate: DateTime | null;
}



export const renderContactItem = (contact: any, type: string) => {
  return (
    <div className="flex flex-col">
      <p className="text-xs">
        {contact.name} - <strong>{type}</strong>
      </p>
      <a className="flex flex-row gap-3 justify-between border-b">
        <p className="text-blue-600 hover:underline text-xs">{contact.email}</p>
        <img
          src={require("../../assets/copy.png")}
          onClick={() => {
            navigator.clipboard.writeText(contact.email);
            toast("Copied!", { autoClose: 1000, type: "info" });
          }}
          className="w-3 h-3 mb-1 rounded-md hover:scale-[1.05] transition-all duration-200 will-change-auto cursor-pointer shadow-lg"
        />
      </a>
      {contact.phone && (
        <div
          className="flex flex-row justify-between border-b mb-1"
          onClick={() => {
            navigator.clipboard.writeText(contact.phone);
            toast("Copied!", { autoClose: 1000, type: "info" });
          }}
        >
          <p className="text-xs">
            <a href={`tel:${contact.phone}`}>{contact.phone}</a>{" "}
          </p>
          <img
            src={require("../../assets/copy.png")}
            className="w-3 h-3 mb-1 rounded-md hover:scale-[1.05] transition-all duration-200 will-change-auto cursor-pointer shadow-lg"
          />
        </div>
      )}
    </div>
  );
};

export const renderActiveIndicator = (company: any, large: boolean = false, showText: boolean = true) => {
  return company.active === true ? (
    <div className="flex flex-row items-center">
      {" "}
      <img
        src={require("../../assets/complete.png")}
        className={`${large ? `w-8 h-8` : `w-3 h-3`} mr-2`}
      />
      {showText && <p className={`${!large ? `text-xs` : `text-lg`}`}>Active</p>}
    </div >
  ) : company.paymentDetails && company.paymentDetails.status != "manual" ? (
    <div className="flex flex-row  items-center">
      {" "}
      <img src={require("../../assets/credit-card.png")} className={`${large ? `w-8 h-8` : `w-3 h-3`} mr-2`} />
      {showText && <p className={`${!large ? `text-xs` : `text-lg`}`}>Payment Setup</p>}
    </div >
  ) : (
    <div className="flex flex-row  items-center">
      {" "}
      <img src={require("../../assets/new.png")} className={`${large ? `w-8 h-8` : `w-3 h-3`} mr-2`} />
      {showText && <p className={`${!large ? `text-xs` : `text-lg`}`}>New Lead</p>}
    </div >
  );
};

const DynamicTable: React.FC<DynamicTableProps> = ({
  tableId,
  cols,
  rows,
  heading,
  onRowClicked,
  loading,
  smallHeader,
  idIndexInColumns,
  showID,
  searchKeys,
  searchQueryInput,
  rowClasses,
  filterableColumns, // Add this prop to define which columns are filterable
  showExport,
  onFiltered,
  rowExpansionComponents,
}) => {
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc" | null;
  }>({
    key: "",
    direction: null,
  });

  // Helper function to detect if the column values are dates
  const isDateColumn = (rows: any, columnIndex: any) => {
    if (!rows || !cols || columnIndex === undefined || !cols[columnIndex]) {
      return false;
    }
    return cols[columnIndex]?.toLowerCase().includes("date");
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [columnFilters, setColumnFilters] = useState<{ [key: string]: string[] }>({});
  const [dateFilters, setDateFilters] = useState<{ [key: string]: DateFilter }>({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filterColumn, setFilterColumn] = useState<string | null>(null);
  const [startDate, setStartDate] = useState(DateTime.now().startOf("year"))
  const [endDate, setEndDate] = useState(DateTime.now().endOf("day"))
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());

  // Load filters and sort from local storage when component mounts
  useEffect(() => {
    const storedState = localStorage.getItem(`tableState-${tableId}`);
    if (storedState) {
      const { savedSortConfig, savedColumnFilters, savedDateFilters } = JSON.parse(storedState);
      setSortConfig(savedSortConfig);
      setColumnFilters(savedColumnFilters);
      setDateFilters(savedDateFilters || {});

    }
  }, [tableId]);

  // Store filters and sort in local storage when they change
  useEffect(() => {
    const tableState = {
      savedSortConfig: sortConfig,
      savedColumnFilters: columnFilters,
      savedDateFilters: dateFilters,

    };
    localStorage.setItem(`tableState-${tableId}`, JSON.stringify(tableState));
  }, [sortConfig, columnFilters, tableId]);

  const handleDateFilterChange = (column: any) => {
    if (startDate && endDate) {
      setDateFilters((prev) => ({
        ...prev,
        [column]: { startDate, endDate },
      }));
    }
  };

  const extractTextContent = (element: any): string => {
    if (typeof element === "string") {
      return element.replace("'", "");
    }
    return ReactDOMServer.renderToStaticMarkup(element)
      .replace(/<[^>]*>/g, " ")
      .trim();
  };

  const handleFilterClick = (column: string, event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterColumn(column);
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
    setFilterColumn(null);
  };

  const handleFilterSelect = (column: string, value: string) => {
    setColumnFilters((prev) => {
      const newFilters = { ...prev };
      if (newFilters[column]?.includes(value)) {
        newFilters[column] = newFilters[column].filter((v) => v !== value);
      } else {
        newFilters[column] = [...(newFilters[column] || []), value];
      }
      return newFilters;
    });
  };

  const sortedRows = useMemo(() => {
    if (sortConfig.key && rows) {
      return [...rows].sort((a, b) => {
        const aValue = extractTextContent(a[cols.indexOf(sortConfig.key)]);
        const bValue = extractTextContent(b[cols.indexOf(sortConfig.key)]);

        if (aValue < bValue) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return rows;
  }, [rows, sortConfig, cols]);

  // Filter rows based on selected column filters
  const filteredRows = useMemo(() => {
    let rowsToFilter = sortedRows;

    // Apply date filters
    Object.keys(dateFilters).forEach((column) => {
      let { startDate, endDate } = dateFilters[column]; // Start with strings
      const columnIndex = cols.indexOf(column);

      if (startDate && endDate) {
        // Convert startDate and endDate from strings to DateTime objects


        if (startDate.isValid && endDate.isValid) {
          rowsToFilter = rowsToFilter?.filter((row) => {
            const cellValue = row[columnIndex];
            const cellContent = extractTextContent(cellValue);

            let date;

            // Determine if the cell value is a timestamp (milliseconds) or ISO string
            if (!isNaN(Number(cellContent))) {
              // Convert from milliseconds timestamp to DateTime
              console.log(`Parsing as timestamp: ${cellContent}`);
              date = DateTime.fromMillis(Number(cellContent));
            } else {
              // Attempt to parse as ISO string
              console.log(`Parsing as ISO: ${cellContent}`);
              date = DateTime.fromISO(cellContent);
            }

            // Ensure the date is valid
            if (!date.isValid) {
              console.error(`Invalid date encountered: ${cellContent}`);
              return false; // Skip rows with invalid dates
            }

            // Normalize dates to ignore time zones by converting to UTC
            const normalizedDate = date.toUTC().startOf("day");
            const normalizedStartDate = startDate ?? DateTime.now()
            const normalizedEndDate = endDate ?? DateTime.now()

            // Log comparison for debugging
            console.log("Testing: ", normalizedDate.toISO(), " Start: ", normalizedStartDate?.toISO(), " End: ", normalizedEndDate?.toISO());
            console.log("Comparison result: ", normalizedDate >= normalizedStartDate && normalizedDate <= normalizedEndDate);

            // Perform the date range comparison
            return normalizedDate >= normalizedStartDate && normalizedDate <= normalizedEndDate;
          });
        } else {
          console.error(`Invalid startDate or endDate: startDate = ${startDate}, endDate = ${endDate}`);
        }
      }
    });

    Object.keys(columnFilters).forEach((column) => {
      const filterValues = columnFilters[column];
      const columnIndex = cols.indexOf(column);

      if (filterValues && filterValues.length > 0 && rowsToFilter) {
        rowsToFilter = rowsToFilter.filter((row) =>
          filterValues.includes(extractTextContent(row[columnIndex]))
        );
      }
    });

    const query = searchQueryInput !== undefined ? searchQueryInput : searchQuery;
    if (!query) return rowsToFilter;

    const searchLower = query?.toLowerCase();
    return rowsToFilter?.filter((row) => {
      return row.some((cell: any, index: number) => {
        if (!showID && index === idIndexInColumns) return false;

        const colHeader = cols[index];
        if (searchKeys && !searchKeys.includes(colHeader)) return false;

        const cellContent = extractTextContent(cell)?.toLowerCase();
        return cellContent.includes(searchLower);
      });
    });
  }, [
    searchQueryInput,
    searchQuery,
    sortedRows,
    cols,
    showID,
    idIndexInColumns,
    searchKeys,
    columnFilters,
    dateFilters
  ]);

  // Reorder rowClasses based on the current filteredRows
  const reorderedRowClasses = useMemo(() => {
    if (!rowClasses || rowClasses.length === 0) return [];
    const reordered = filteredRows.map((row) => {
      const originalIndex = rows.indexOf(row); // Find the original index of this row
      return rowClasses[originalIndex]; // Use the corresponding class from the original rowClasses array
    });
    return reordered;
  }, [filteredRows, rowClasses, rows]);

  const handleSort = (column: string) => {
    let direction: "asc" | "desc" = "asc";
    if (sortConfig.key === column && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: column, direction });
  };

  const uniqueColumnValues = (column: string) => {
    const columnIndex = cols.indexOf(column);
    const uniqueValues = new Set<string>();

    rows.forEach((row) => {
      const value = extractTextContent(row[columnIndex]);
      if (value) uniqueValues.add(value);
    });

    // Convert the Set to an array and sort alphabetically (A-Z, 0-9)
    return Array.from(uniqueValues).sort((a, b) => a.localeCompare(b));
  };

  // Function to download CSV
  const downloadCSV = () => {
    const csvRows = [];
    const headers = cols.map((col) => `${col}`).join(";");
    csvRows.push(headers);

    filteredRows.forEach((row) => {
      const csvRow = row
        .map((cell: any) => {
          const cellContent = extractTextContent(cell).replace(/"/g, '""');
          return `${cellContent}`;
        })
        .join(";");
      csvRows.push(csvRow);
    });

    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${heading || "table_data"}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };



  const clearFilters = () => {
    localStorage.removeItem(`tableState-${tableId}`);
    setColumnFilters({});
    setSortConfig({ key: "", direction: null });
  };

  useEffect(() => {
    onFiltered && onFiltered(filteredRows)
  }, [filteredRows])

  // Function to format date for display
  const formatDateForDisplay = (dateString: string): string => {
    let date = DateTime.fromISO(extractTextContent(dateString));

    // If the date is valid, format it; otherwise return the original string
    return date.toFormat("yyyy-MM-dd HH:mm").toString();
  };

  const toggleRowExpansion = (index: number, event: React.MouseEvent) => {
    event.stopPropagation();
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };



  return (
    <div className="mb-4 bg-white border-2 border-primary p-4 md:px-4 rounded-xl w-full">
      {heading && <h2 className="text-3xl">{heading}</h2>}
      <div className="flex flex-row w-full gap-4">
        {(searchQueryInput === undefined || searchQueryInput === null) && (
          <div className="flex flex-col justify-center flex-grow">
            <SearchBox value={searchQuery} onChange={(e: any) => setSearchQuery(e.target.value)} />
          </div>
        )}
        {showExport && (
          <>
            <Button
              variant="outlined"
              // sx={{ backgroundColor: "#EEE", fontSize: "10px", textAlign: "center", ":hover": { backgroundColor: "#888" } }}
              onClick={downloadCSV}
            >
              <img src={require("../../assets/download.png")} className="w-4 h-4" />
              <p className="text-darkgrey text-xs font-medium">Export Table to CSV</p>
            </Button>
            <Button
              variant="outlined"
              // sx={{ backgroundColor: "#EEE", fontSize: "10px", textAlign: "center", ":hover": { backgroundColor: "#888" } }}
              onClick={clearFilters}
            >
              <p className="text-[#333] text-xs font-medium">Clear Filters</p>
            </Button>
          </>
        )}
      </div>
      <p className="text-xs text-gray-500 mb-2 ml-2 italic">{filteredRows?.length ?? 0} results</p>
      {loading ? (
        <div className="flex flex-row w-full min-h-[400px] items-center justify-center">
          <CircularProgress size={80} color="warning" />
        </div>
      ) : (
        <div className="w-full flex overflow-x-scroll">
          <Table className="w-1/2 rounded-lg">
            <TableHead>
              <TableRow className="border-b-2 border-primary m-0 p-0">
                {cols?.map((col: any, index: number) => {
                  if (!showID && index === idIndexInColumns) return null;

                  // Check if the column has an active filter (either in `columnFilters` or `dateFilters`)
                  const isActiveFilter = columnFilters[col]?.length > 0 || dateFilters[col]?.startDate || dateFilters[col]?.endDate;

                  return (
                    <TableCell key={index} sx={{ paddingX: "4px", paddingY: "8px" }} className="font-montserrat p-0 m-0">
                      <div className="flex flex-row items-center justify-start m-0 p-0">
                        <TableSortLabel
                          active={sortConfig.key === col}
                          direction={sortConfig.direction || "asc"}
                          onClick={() => handleSort(col)}
                        >
                          <h4 className={`text-primary font-[700] text-base mb-0 pb-0`}>
                            {col}
                          </h4>
                        </TableSortLabel>

                        {/* Check if the column is filterable */}
                        {filterableColumns?.includes(col) && (
                          <IconButton onClick={(e) => handleFilterClick(col, e)}  >
                            {/* Change the colour of the filter icon based on active filter */}
                            <FilterList sx={{ color: isActiveFilter ? "red" : "inherit", width: "20px", height: "20px" }} />
                          </IconButton>
                        )}
                      </div>
                    </TableCell>
                  );
                })}
                {rowExpansionComponents && (
                  <TableCell padding="none" sx={{ width: "48px" }}>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredRows?.map((row: any, index: number) => (
                <React.Fragment key={index}>
                  <TableRow
                    role="link"
                    data-href={`/application/${extractTextContent(row[idIndexInColumns])}`}

                    className={`hover:bg-blue-100 cursor-pointer  ${reorderedRowClasses && reorderedRowClasses[index]
                      }`}
                    onClick={() => {
                      const rowID = extractTextContent(row[idIndexInColumns]);
                      onRowClicked(rowID);
                    }}
                    sx={{
                      // Remove the bottom border if this row is expanded
                      '& > td': {
                        borderBottom: expandedRows.has(index) ? 'none' : undefined
                      },
                      paddingY: "10px"
                    }}
                  >
                    {row.map((cell: any, cellIndex: number) => {
                      if (!showID && cellIndex === idIndexInColumns)
                        return null;

                      const colHeader = cols[cellIndex];
                      const isDateCol = colHeader ? colHeader?.toLowerCase().includes("date") : false;
                      return (
                        <TableCell
                          key={cellIndex}
                          sx={{
                            margin: "0px",
                            paddingX: "4px",
                            paddingY: "8px",
                          }}
                        >
                          <p className="text-xs ">
                            {isDateCol
                              ? formatDateForDisplay(cell)
                              : cell}
                          </p>
                        </TableCell>
                      );
                    })}
                    {rowExpansionComponents && rowExpansionComponents[index] && (
                      <TableCell padding="none" sx={{ width: "48px" }}>
                        <IconButton
                          className={`chevron ${expandedRows.has(index) ? "open" : ""
                            }`}
                          onClick={(e) => toggleRowExpansion(index, e)}
                        >
                          <KeyboardArrowDown fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                  {rowExpansionComponents && rowExpansionComponents[index] && (
                    <TableRow sx={{ padding: "0px", margin: "0px" }}>
                      <TableCell
                        colSpan={showID ? cols.length + 1 : cols.length}
                        sx={{ padding: 0 }}
                      >
                        <div
                          className={`expandable-content ${expandedRows.has(index) ? "open" : ""
                            }`}
                        >
                          {rowExpansionComponents[index]}
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
              {filteredRows?.length == 0 && (
                <TableRow className="w-full flex flex-row bg-red/20 col-span-5 items-center justify-center">
                  <TableCell colSpan={cols.length}>
                    <h3 className="text-center w-full">No data to show.</h3>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      )}

      {/* Filter dropdown menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleFilterClose}
        MenuListProps={{
          "aria-labelledby": "filter-button",
        }}
      >

        {filterColumn && (isDateColumn(rows, cols.indexOf(filterColumn)) ? (
          <div style={{ padding: "10px" }}>
            <DateRangePicker
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              onDatesSelected={() => handleDateFilterChange(filterColumn!)}
              mini={true}
            />
          </div>
        ) : (
          /* Non-date columns' filter options can go here (e.g., checkbox list) */

          uniqueColumnValues(filterColumn).map((value, index) => (<>
            <MenuItem
              className="flex flex-row gap-2"
              key={index}
              onClick={() => handleFilterSelect(filterColumn, value)}
            >
              <input
                type="checkbox"
                checked={columnFilters[filterColumn]?.includes(value) || false}
                onChange={() => handleFilterSelect(filterColumn, value)}
              />
              <span className="font-montserrat text-sm">{value}</span>
            </MenuItem>
          </>
          ))))}

        {/* {filterColumn &&
} */}
      </Menu>
    </div>
  );
};

export default DynamicTable;
