"use client";
import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
import { useUser } from "../../context/AppContext";

import { DateTime } from "luxon";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField"; // Make sure you have @mui/material installed
import { DocusealForm } from "@docuseal/react";
import { toast } from "react-toastify";
import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import ApplicationHistory from "./ApplicationHistory";
import ResponsiveAppBar from "../../components/Navbar";
import { useParams, useLocation, useNavigate } from "react-router-dom";

const Application = () => {
  const {
    selectedApplication,
    setSelectedApplication,
    resendNewApplicationEmails,
    runningAllApplicationsQuery,
    setRunningAllApplicationsQuery,
    selectedCompany,
    resendHRApprovalEmail,
    renewApplication,

    companies,
    companyApplications,
    allApplications,
    setSelectedCompany,
    cancelApplication,
  } = useUser();
  const localStorageKey = "form_data";
  const [formData, setFormData] = useState<any>({ surname: "", email: "", reqError: "", twoFACode: "" });
  const [email, setEmail] = useState("");
  const [surname, setSurname] = useState("");
  const [error, setError] = useState<any>(false);
  const [loading, setLoading] = useState(false);
  const [show2FAForm, setShow2FAForm] = useState(false);
  const [showProductChangeOptions, setShowProductChangeOptions] = useState(false);
  const [selectedProductToChangeTo, setSelectedProductToChangeTo] = useState<any>();
  const [showCancellationConfirmation, setShowCancellationConfirmation] = useState(false);
  const [localTaxBandValue, setLocalTaxBandValue] = useState<any>();
  const [localSelectedTaxRateObject, setLocalSelectedTaxRateObject] = useState<any>({});
  const routeParams = useParams();
  const location = useLocation()
  const navigate = useNavigate();

  // const [selectedApplication, setSelectedApplication] = useState<any>();
  const [formErrors, setFormErrors] = useState<any>({});
  const [initialised, setInitialised] = useState(false);
  useEffect(() => {
    console.log(initialised, selectedApplication);
    if (!initialised || !selectedApplication) {
      //   getApplication();

      setInitialised(true);
    }
  }, []);

  useEffect(() => {
    console.log("All applications: " + allApplications?.length);
    if (allApplications && allApplications.length > 0) {
      console.log("THIS IS THE APPLICATION: " + "");
      setSelectedApplication(allApplications.filter((a: any) => a.id == routeParams?.applicationid)[0]);
    }
  }, [allApplications]);

  useEffect(() => {
    console.log("All companies: " + companies?.length);
    if (companies && companies.length > 0) {
      console.log("THIS IS THE APPLICATION: " + "");
      setSelectedCompany(companies.filter((a: any) => a.companyID == routeParams?.companyid)[0]);
    }
  }, [companies]);

  //   const getApplication = async () => {
  //     if (id) {
  //       setLoading(true);

  //       console.log("This is the app id " + id);
  //       applicationService
  //         .getApplication({ appID: id, twoFACode: formData.twoFACode })
  //         .then((response) => {
  //           /**
  //            * TODO: FIX THIS SHIT IT"S BROKEN
  //            *
  //            */
  //           console.log(response);
  //           if (!response) {
  //             toast.info("Please sign in again to view your application.", {
  //               position: "top-center",
  //               autoClose: 5000,
  //               hideProgressBar: false,
  //               closeOnClick: true,
  //               pauseOnHover: true,
  //               draggable: true,
  //               progress: undefined,
  //             });
  //             setSelectedApplication(null);
  //           } else {
  //             setShow2FAForm(false);
  //             setSelectedApplication(response.data.application);
  //           }
  //           setLoading(false);
  //         })
  //         .catch((err) => {
  //           console.log("THIS IS THE ERROR", err);
  //           if (err.error == "NOAUTH") {
  //             setSelectedApplication(null);
  //           } else if (err.data.error == "TOKEN INVALID") {
  //             toast("Please sign in again to view your application.");
  //           } else {
  //             setError(true);
  //           }
  //           setLoading(false);

  //           console.log(err);
  //         });
  //     } else {
  //       console.log("Errors exist");
  //       setLoading(false);
  //     }
  //   };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear the corresponding error when the field is changed
    setFormErrors({
      ...formErrors,
      [name]: undefined,
      reqError: undefined,
    });
    localStorage.setItem(localStorageKey, JSON.stringify({ ...formData, [name]: value }));
  };

  const actionButton = (image: string, text: string, click: any, useGreenBG?: boolean) => {
    return (
      <div
        onClick={click}
        className={`flex flex-row w-full items-center justify-center py-1  rounded-lg h-full  ${useGreenBG
          ? `hover:bg-white border-2 bg-secondary/50 border-secondary`
          : `bg-lightgrey border border-white hover:bg-white`
          }  transition-all duration-200 hover:cursor-pointer`}
      >
        <img src={image} className="h-8 w-8 object-cover p-2" />
        <p className="text-base">{text}</p>
      </div>
    );
  };

  const productInfoCard = (product: any, clickable: boolean) => {
    return (
      <div
        onClick={() => {
          setSelectedProductToChangeTo(product);
        }}
        className={`bg-white flex flex-row max-w-1/2 relative p-2 border-b-2 items-center  transform duration-300 
                              ${clickable &&
          `hover:bg-secondary/50 hover:rounded-lg hover:border-b-secondary cursor-pointer`
          }`}
      >
        <div
          className={`h-10 w-10 min-w-[60px] min-h-[60px] md:h-20 md:w-20 m-2 rounded-md overflow-hidden  flex-none`}
        >
          <img src={product?.imageURL} className="w-full h-full object-cover" />
        </div>

        <div className="flex flex-col items-start max-w-1/2">
          <p className="text-lg text-primary text-left font-montserrat font-medium">{product?.name}</p>
          {/* <h4>{product?.heading}</h4> */}
          <p className="text-sm text-left font-montserrat font-normal">{product?.description}</p>
        </div>
        {/* {selected && <img src={require("../../assets/accept.png")} className="absolute top-2 right-2 w-5 h-5 md:w-6 md:h-6" />} */}
      </div>
    );
  };

  const variants = {
    hidden: {
      height: 0,
      opacity: 0,
      transition: {
        height: { duration: 0.3 },
        opacity: { duration: 0.3 },
      },
    },
    visible: {
      height: "auto",
      opacity: 1,
      transition: {
        height: { duration: 0.3 },
        opacity: { duration: 0.3, delay: 0.25 },
      },
    },
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  return (
    <div className="flex flex-col w-full mx-2 md:mx-4 my-5">
      <ResponsiveAppBar />

      {
        selectedApplication && (
          <div className={`bg-white flex flex-col`}>
            <div className={`bg-white flex flex-col  w-full max-w-[1024px] mx-auto`}>

            </div>
            <div className={`p-8 w-full max-w-[900px] mx-auto font-montserrat bg-white z-1 flex flex-col   `}>

              <ProgressBar
                currentStep={
                  selectedApplication.status == "ACTIVE"
                    ? 5
                    : selectedApplication.status == "INFORMATION_REQUIRED"
                      ? 3
                      : selectedApplication.status == "AWAITING_ACTIVATION"
                        ? 4
                        : selectedApplication.status == "AWAITING_EMPLOYEE_SIGNATURE"
                          ? 2
                          : selectedApplication.status == "AWAITING_EMPLOYER_SIGNATURE"
                            ? 1
                            : 0
                }
                stepsCustom={[
                  "Processing",
                  "Employer signature",
                  "Employee signature",
                  "Information Required",
                  "Activating",
                  "Active",
                ]}
                stepClicked={(index: any) => { }}
                cancelled={selectedApplication.status == "CANCELLED"}
                cancelledDateTime={selectedApplication.cancellationDateTime}
              />
            </div>

            <div
              id="apply"
              className={`px-4 md:p-0 mb-4 max-w-[1024px] mx-auto font-montserrat bg-white z-1 flex flex-col w-full opacity-100  duration-200 transform`}
            >
              {/* {selectedApplication.status == "AWAITING_ACTIVATION" && (
                <div className="w-full flex flex-col items-start bg-secondary p-2  rounded-xl my-2">
                  <div className="w-full flex flex-row items-center bg-white rounded-md p-3 px-2 md:px-4">
                    <img src={require("../../assets/mark.png")} className="w-8 md:w-16" />
                    <div className="w-full flex flex-col items-start bg-white rounded-md pl-2 md:pl-4">
                      <h2>This application is awaiting activation.</h2>
                      <p className="text-left">Your application has been approved and is awaiting activation.</p>
                      {selectedApplication.benefit.renewalConfiguration.distributionMethod == "VOUCHER" ? (
                        <p className="text-left font-bold">
                          We will email and SMS you on the 1st of the month with an activation link and voucher code
                          which you can redeem in the {selectedApplication.benefit.partnerName} app.
                        </p>
                      ) : (
                        <p className="text-left font-bold">
                          Your subscription will be activated on your {selectedApplication.benefit.partnerName} account
                          automatically on the 1st of next month.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )} */}
              <div className="flex flex-col  md:mt-2">
                <div className="flex flex-col border bg-lightgrey rounded-lg p-4 px-6 gap-2 my-4">
                  {" "}
                  <p className="font-semibold">ACTIONS</p>
                  <div className="flex flex-row  gap-6 ">
                    {selectedCompany &&
                      <button className="flex flex-row gap-2 items-center justify-start cursor-pointer bg-white " onClick={() => { navigate(`/companies/${selectedCompany.companyID}`) }}>
                        <img src={require("../../assets/company.png")} className="w-7 h-7" />
                        <p className="hover:underline">View {selectedCompany?.name}</p>
                      </button>}
                    {selectedApplication?.status == "AWAITING_EMPLOYER_SIGNATURE" ||
                      (selectedApplication?.status == "PROCESSING" && (
                        <button

                          onClick={resendHRApprovalEmail}
                          disabled={loading}
                        >
                          <img src={require("../../assets/signature.png")} className="w-6 h-6" />

                          Resend HR email for approval
                        </button>
                      ))}

                    {selectedApplication?.status == "PROCESSING" ||
                      (selectedApplication?.status == "AWAITING_EMPLOYER_SIGNATURE" && (
                        <button
                          disabled={loading}
                          className={`font-montserrat font-medium bg-darkgrey text-secondary max-w-[300px] ${runningAllApplicationsQuery && `bg-white`
                            }`}
                          onClick={() => {
                            setRunningAllApplicationsQuery(true);
                            resendNewApplicationEmails().then(() => {
                              setRunningAllApplicationsQuery(false);
                            });
                          }}
                        >
                          <p className="text-secondary text-center  text-base font-semibold">
                            {!runningAllApplicationsQuery ? `Send employer email again` : `Loading...`}
                          </p>
                        </button>
                      ))}{" "}
                    {selectedApplication?.docusealSubmission?.signedContractLink && (
                      <a
                        href={selectedApplication?.docusealSubmission?.signedContractLink}
                        download
                        className={`font-montserrat px-3 rounded-md py-2 font-semibold bg-white text-darkgrey shadow-md max-w-[300px] ${runningAllApplicationsQuery && `bg-white`
                          }`}
                        target="_blank"
                        style={{ display: "inline-block", textDecoration: "none" }}
                      >
                        <div className="flex flex-row gap-2 items-center justify-center">
                          <img src={require("../../assets/download.png")} className="w-6 h-6" />
                          <p>Download signed contract</p>
                        </div>{" "}
                      </a>
                    )}
                    {(selectedApplication?.status == "AWAITING_ACTIVATION" || selectedApplication?.status == "ACTIVE" || selectedApplication?.status == "PENDING_PRODUCT_CHANGE") &&
                      (
                        <button
                          className="font-montserrat font-semibold max-w-[300px] bg-white flex flex-row gap-2"
                          onClick={renewApplication}
                          disabled={loading}
                        >
                          <img src={require("../../assets/send.png")} className="w-6 h-6" />

                          <p className="text-darkgrey text-center text-base font-semibold">
                            {selectedApplication.benefit.renewalConfiguration.distributionMethod == "VOUCHER" ? "Send Voucher" : "Mark as Completed"}
                          </p>
                        </button>
                      )}
                  </div>
                </div>
                <div className="flex flex-row flex-wrap justify-between">
                  <div className="flex flex-col flex-wrap justify-between">
                    <p className="font-medium text-base text-primary break-all text-left">Application #</p>
                    <p className="text-2xl font-bold text-primary mb-2 break-all text-left">{selectedApplication.id}</p>
                  </div>

                  <div className="flex flex-col flex-wrap justify-between">
                    {/* <h2>{selectedApplication.status}</h2> */}
                    <p className="font-medium text-base text-primary break-all text-left">Submitted on</p>
                    <p className="text-2xl font-bold text-primary mb-2 break-all text-left ">
                      {" "}
                      {DateTime.fromISO(selectedApplication.creationDateTime).toFormat("dd LLLL yyyy HH:mm:ss a")}
                    </p>
                  </div>
                </div>
                <p className="font-medium text-lg text-primary break-all text-left">Product</p>
                {selectedApplication.userProviderAccountDetails &&
                  selectedApplication.status == "AWAITING_ACTIVATION" && (
                    <div className="bg-secondary rounded-xl p-4">
                      <h4>
                        The following subscription package will be activated on the account with email{" "}
                        <span className="underline font-bold">
                          {selectedApplication.userProviderAccountDetails.email}
                        </span>
                      </h4>
                    </div>
                  )}{" "}
                {selectedApplication.status == "PENDING_PRODUCT_CHANGE" && (
                  <div className="ml-2">
                    <p className="text-red  italic text-left ">There is a change pending on the active product.</p>
                  </div>
                )}
                <div
                  className={`flex flex-row items-center justify-center flex-wrap gap-2 my-2 border p-2 rounded-xl bg-lightgrey`}
                  key="outer"
                >
                  <div
                    className={` flex flex-col w-full relative p-2  rounded-lg items-start  transform duration-100 
   ${selectedApplication.status == "PENDING_PRODUCT_CHANGE" ? `bg-orange-100` : `bg-white`}`}
                    key="mainProduct"
                  >
                    <div className="flex flex-row items-center">
                      {selectedApplication.benefit?.imageURL && (
                        <div
                          className={`h-10 w-10 min-w-[60px] min-h-[60px] md:h-20 md:w-20 m-2 rounded-md overflow-hidden  flex-none`}
                        >
                          <img src={selectedApplication.benefit?.imageURL} className="w-full h-full object-cover" />
                        </div>
                      )}
                      {selectedApplication.benefit && (
                        <div className="flex flex-col items-start">
                          <p className="text-lg text-primary text-left font-montserrat font-medium">
                            {selectedApplication.benefit.name}
                          </p>
                          {/* <h4>{selectedApplication.benefit.heading}</h4> */}
                          <p className="text-sm text-left font-montserrat font-normal">
                            {selectedApplication.benefit.description}
                          </p>
                        </div>
                      )}
                    </div>
                    {selectedApplication.status == "PENDING_PRODUCT_CHANGE" && (
                      <div className="ml-2 flex flex-row items-center my-2">
                        <img className="ml-2 w-5 h-5" src={require("../../assets/info.png")} />

                        <p className="text-red font-light text-sm italic">
                          This product will expire on{" "}
                          {DateTime.fromISO(
                            selectedApplication.benefit.expiryDate ?? selectedApplication.pendingBenefit.effectiveDate
                          ).toFormat("dd LLL yyyy")}
                        </p>
                      </div>
                    )}

                    {selectedApplication.salarySacrificeTerms && (
                      <div className="ml-2 flex flex-row items-center my-2">


                        <p className="text-blue-500 font-medium text-sm italic">
                          {selectedApplication.salarySacrificeTerms.numberOfSacrifices >= 0 ? `The applicant will repay this product over ${selectedApplication.salarySacrificeTerms.numberOfSacrifices} salary sacrifices.` : `Salary sacrifices for this product will continue until the employee cancels the application.`}
                        </p>
                      </div>
                    )}
                    {/* {{ selectedApplication.salarySacrificeTermsOptions }} */}
                    {/* {selected && <img src={require("../../assets/accept.png")} className="absolute top-2 right-2 w-5 h-5 md:w-6 md:h-6" />} */}
                  </div>
                  {selectedApplication.status == "PENDING_PRODUCT_CHANGE" && selectedApplication.pendingBenefit && (
                    <div
                      className={` flex flex-col max-w-1/2 relative p-2  rounded-lg items-start  transform duration-100 
                    ${selectedApplication.status == "PENDING_PRODUCT_CHANGE" ? `bg-green-100` : `bg-white`}`}
                      key="mainProduct"
                    >
                      <div className="flex flex-row items-center">
                        {selectedApplication.pendingBenefit?.imageURL && (
                          <div
                            className={`h-10 w-10 min-w-[60px] min-h-[60px] md:h-20 md:w-20 m-2 rounded-md overflow-hidden  flex-none`}
                          >
                            <img
                              src={selectedApplication.pendingBenefit?.imageURL}
                              className="w-full h-full object-cover"
                            />
                          </div>
                        )}
                        {selectedApplication.pendingBenefit && (
                          <div className="flex flex-col items-start">
                            <p className="text-lg text-primary text-left font-montserrat font-medium">
                              {selectedApplication.pendingBenefit.name}
                            </p>
                            {/* <h4>{selectedApplication.pendingBenefit.heading}</h4> */}
                            <p className="text-sm text-left font-montserrat font-normal">
                              {selectedApplication.pendingBenefit.description}
                            </p>
                          </div>
                        )}
                      </div>

                      <div className="ml-2 flex flex-row items-center my-2">
                        <img className="ml-2 w-5 h-5" src="info.png" />

                        <p className="text-darkgrey font-light text-sm italic">
                          This product will activate on{" "}
                          {DateTime.fromISO(selectedApplication.pendingBenefit.effectiveDate).toFormat("dd LLL yyyy")}
                        </p>
                      </div>
                    </div>
                  )}
                  {/* {selected && <img src={require("../../assets/accept.png")} className="absolute top-2 right-2 w-5 h-5 md:w-6 md:h-6" />} */}
                  {/* <AnimatePresence initial={false} mode="wait">
                  {showProductChangeOptions && (
                    // ? (
                    //   <motion.div
                    //     className="flex flex-row items-center justify-center gap-1 w-full"
                    //     key="buttons"
                    //     initial="hidden"
                    //     animate="visible"
                    //     exit="hidden"
                    //     variants={variants}
                    //     style={{ overflow: "hidden" }}
                    //   >
                    //     {(selectedApplication.upgradeOptions || selectedApplication.downgradeOptions) &&
                    //       actionButton(
                    //         "/change2.png",
                    //         showProductChangeOptions ? "Nevermind, I don't want to change my product" : "Change Product",
                    //         () => {
                    //           console.log("Clicked");
                    //           setShowProductChangeOptions(!showProductChangeOptions);
                    //         }
                    //       )}

                    //   </motion.div>
                    // ) :
                    <motion.div
                      initial={{
                        height: 0,
                        opacity: 0,
                      }}
                      animate={{
                        height: "auto",
                        opacity: 1,
                        transition: {
                          height: {
                            duration: 0.5,
                          },
                          opacity: {
                            duration: 0.3,
                            delay: 0.4,
                          },
                        },
                      }}
                      exit={{
                        height: 0,
                        opacity: 0,
                        transition: {
                          height: {
                            duration: 0.3,
                          },
                          opacity: {
                            duration: 0.25,
                          },
                        },
                      }}
                      key="changeProductPane"
                      className="font-light  w-full px-4 pt-2 pb-4 bg-white mt-1 rounded-lg"
                    >
                      <h3
                        className="text-left"
                        onClick={() => {
                          setShowProductChangeOptions(!showProductChangeOptions);
                        }}
                      >
                        Thinking of changing your product?
                      </h3>
                      <p className="text-left text-sm">You can choose from one of the products below:</p>
                      <div className="flex flex-row flex-wrap gap-1 w-full mt-2">
                        {selectedApplication?.upgradeOptions?.map((product) => {
                          return productInfoCard(product, true);
                        })}
                        {selectedApplication?.downgradeOptions?.map((product) => {
                          return productInfoCard(product, true);
                        })}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence> */}

                  {/* <div className="flex flex-row items-center justify-center gap-1 w-full  mt-1">
                  {(selectedApplication.upgradeOptions || selectedApplication.downgradeOptions) &&
                    selectedApplication.status != "PENDING_PRODUCT_CHANGE" &&
                    !showProductChangeOptions &&
                    actionButton("/change2.png", "Change Product", () => {
                      console.log("Clicked");
                      setShowProductChangeOptions(!showProductChangeOptions);
                    })}
                  {showProductChangeOptions &&
                    actionButton("/change2.png", "Nevermind, I don't want to change my product", () => {
                      console.log("Clicked");
                      setShowProductChangeOptions(!showProductChangeOptions);
                    })}
                </div> */}
                  {/* {(selectedApplication.upgradeOptions?.length > 0 ||
                    selectedApplication.downgradeOptions?.length > 0) &&
                    selectedApplication.status == "ACTIVE" &&
                    !showProductChangeOptions && (
                      <div
                        className="flex flex-row items-center justify-center gap-1 w-full 
               mt-1"
                      >
                        {actionButton(
                          "/change2.png",
                          showProductChangeOptions ? "Nevermind, I don't want to change my product" : "Change Product",
                          () => {
                            console.log("Clicked");
                            setShowProductChangeOptions(!showProductChangeOptions);
                          }
                        )}
                      </div>
                    )}
                  {showProductChangeOptions && (
                    <div
                      className="flex flex-row items-center justify-center gap-1 w-full 
               mt-1"
                    >
                      {actionButton("/cancel.png", "Nevermind, I don't want to change my product", () => {
                        console.log("Clicked");
                        setShowProductChangeOptions(!showProductChangeOptions);
                      })}
                    </div>
                  )} */}
                </div>
                {/* <BenefitCard benefit={selectedApplication.benefit} selected={false} /> */}
                <div className="flex flex-col items-start w-full my-2 border px-4 py-2 rounded-md bg-blue-100 ">
                  <div className="flex flex-col items-start">
                    {selectedApplication.employee && (
                      <>
                        <h4 className="font-medium text-base text-primary break-all text-left">Employee</h4>

                        <p className="font-semibold text-base text-left ml-2">
                          {selectedApplication.employee.firstName + " " + selectedApplication.employee.surname}
                        </p>
                        {selectedApplication.employee.phone && (
                          <>
                            <p className="font-semibold text-base text-left ml-2">
                              {selectedApplication.employee.phone}
                            </p>
                          </>
                        )}
                        {selectedApplication.employee.email && (
                          <>
                            <p className="font-semibold text-base text-left ml-2">
                              {selectedApplication.employee.email}
                            </p>
                          </>
                        )}
                        {/* <p className="font-semibold text-base text-left">
                        Email: <p className="text-left">{selectedApplication.company.email}</p>
                      </p>{" "} */}
                        {selectedApplication.employee.address && (
                          <>
                            <p className="font-semibold text-base text-left ml-2">
                              {selectedApplication.employee.address}
                            </p>
                          </>
                        )}
                        {selectedApplication.employee.postcode && (
                          <>
                            <p className="font-semibold text-base text-left ml-2">
                              {selectedApplication.employee.postcode}
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="flex flex-col items-start w-full border px-4 py-2 rounded-md bg-purple-100">
                  <div className="flex flex-col items-start">
                    <h4 className="font-medium text-base text-primary break-all text-left">Employer</h4>
                    {selectedApplication.company && (
                      <>
                        <p className="font-semibold text-base text-left ml-2">{selectedCompany?.name}</p>

                        <p className="font-semibold text-base text-left ml-2">{selectedCompany?.address}</p>
                        <div className="ml-2">
                          <h6>HR contacts:</h6>
                          <div className="flex flex-row flex-wrap w-full space-x-1">
                            {selectedCompany?.hrContacts.map((contact: any) => {
                              return (
                                <div className="flex flex-col px-2  ">
                                  {" "}
                                  <p className="font-semibold text-base text-left ml-2">{contact.name}</p>
                                  <p className="font-semibold text-base text-left ml-2">{contact.email}</p>
                                  <p className="font-semibold text-base text-left ml-2">{contact.phone}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="ml-2">
                          <h6>Finance contacts:</h6>
                          <div className="flex flex-row flex-wrap w-full space-x-1">
                            {selectedCompany?.financeContacts?.map((contact: any) => {
                              return (
                                <div className="flex flex-col px-2  ">
                                  {" "}
                                  <p className="font-semibold text-base text-left ml-2">{contact.name}</p>
                                  <p className="font-semibold text-base text-left ml-2">{contact.email}</p>
                                  <p className="font-semibold text-base text-left ml-2">{contact.phone}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {selectedApplication.status != "CANCELLED" && (
                  <div className="mt-4 flex flex-row">
                    <div className="sm:w-2/3" />
                    <div className="w-full sm:w-1/3 ">
                      {actionButton(require("../../assets/cancel.png"), "Cancel this application", () => {
                        setShowCancellationConfirmation(true);
                      })}
                    </div>
                  </div>
                )}
              </div>{" "}
              <ApplicationHistory historyList={selectedApplication?.activityLog ?? []} />
            </div>
            <AnimatePresence>
              {/* {selectedProductToChangeTo && (
              <motion.div
                key="overlay"
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={overlayVariants}
                transition={{ duration: 0.2 }}
              >
                {loading ? (
                  <motion.div
                    className="flex flex-col bg-white p-8 rounded-lg items-center justify-center shadow-lg m-2 gap-2"
                    key="loading"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <CircularProgress size={50} style={{ color: "#86eb85" }} />
                    <p>We're updating your selected product...</p>
                  </motion.div>
                ) : (
                  <motion.div
                    key="content"
                    className="flex flex-col bg-white p-8 rounded-lg shadow-lg m-2 gap-2"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    {productInfoCard(selectedProductToChangeTo, false)}
                    <PricingTable
                      benefit={selectedProductToChangeTo}
                      handleSalaryBandChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                        var { name, value } = e.target;
                        if (name == "salaryBand") {
                          console.log("Finding tax rate...");
                          var selectedBand = selectedProductToChangeTo.taxRatePricingData.find((d: any) => {
                            return d.taxClassID == value;
                          });
                          setLocalSelectedTaxRateObject(selectedBand);
                        }
                        setLocalTaxBandValue(value);
                      }}
                      formValue={localTaxBandValue}
                      selectedTaxRateObject={localSelectedTaxRateObject}
                      selectionError={null}
                      setSelectedTaxRateObject={setLocalSelectedTaxRateObject}
                      minified={false}
                    />
                    <div className="w-full h-3" />

                    {actionButton(
                      "accept.png",
                      "Change my product to " + selectedProductToChangeTo.name,
                      () => {
                        setLoading(true);
                        applicationService
                          .modifyApplication(selectedProductToChangeTo.id, selectedApplication.id)
                          .then((response) => {
                            console.log(response);
                            toast.success("Your selected product has been updated!");
                            setTimeout(() => {
                              setLoading(false);
                              getApplication();
                              setSelectedProductToChangeTo(null);
                              setShowProductChangeOptions(false);
                            }, 3000);
                          })
                          .catch((err) => {
                            console.log(err);
                            toast.error("An error occurred while updating your product. Please try again.");
                            setLoading(false);
                          });
                      },
                      true
                    )}
                    <div className="w-full h-3" />
                    {actionButton("cancel.png", "Go Back", () => {
                      setSelectedProductToChangeTo(null);
                    })}
                  </motion.div>
                )}
              </motion.div>
            )} */}
              {showCancellationConfirmation && (
                <motion.div
                  key="overlay"
                  className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={overlayVariants}
                  transition={{ duration: 0.2 }}
                >
                  {loading ? (
                    <motion.div
                      className="flex flex-col bg-white p-8 rounded-lg items-center justify-center shadow-lg m-2 gap-2"
                      key="loading"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <CircularProgress size={50} style={{ color: "#86eb85" }} />
                      <p>We're cancelling this product...</p>
                    </motion.div>
                  ) : (
                    <motion.div
                      key="content"
                      className="flex flex-col bg-white p-8 rounded-lg shadow-lg m-2 gap-2"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      {/* <div className="w-full h-3" /> */}
                      <h2>Application Cancellation</h2>
                      <p>Are you sure you would like to proceed with cancelling this application?</p>
                      <p>This cannot be undone.</p>

                      {/* {actionButton(
                  "accept.png",
                  "Change my product to " + selectedProductToChangeTo.name,
                  () => {
                    setLoading(true);
                    applicationService
                      .modifyApplication(selectedProductToChangeTo.id, selectedApplication.id)
                      .then((response) => {
                        console.log(response);
                        toast.success("Your selected product has been updated!");
                        setTimeout(() => {
                          setLoading(false);
                          getApplication();
                          setSelectedProductToChangeTo(null);
                          setShowProductChangeOptions(false);
                        }, 3000);
                      })
                      .catch((err) => {
                        console.log(err);
                        toast.error("An error occurred while updating your product. Please try again.");
                        setLoading(false);
                      });
                  },
                  true
                )} */}
                      <div className="w-full h-3" />
                      {actionButton(
                        require("../../assets/back.png"),
                        "Nevermind, go back",
                        () => {
                          setShowCancellationConfirmation(false);
                        },
                        true
                      )}
                      {actionButton(require("../../assets/cancel.png"), "Proceed with cancellation", async () => {
                        setLoading(true);
                        cancelApplication(selectedApplication.id)
                          .then((res: any) => {
                            console.log(res.data);
                            setLoading(false);
                            if (res.data.success == true) {
                              toast.success("The application has been cancelled!");
                              setSelectedApplication(res.data.finalCancelledApplication);
                              setShowCancellationConfirmation(false);
                            } else {
                              if (res.data.reason == "This application has already been cancelled") {
                                toast.info(
                                  "The application has already been cancelled. Refresh the page to see the updated status."
                                );
                                setShowCancellationConfirmation(false);
                              }
                            }
                          })
                          .catch((err: any) => {
                            console.log(err);
                            setLoading(false);
                            setShowCancellationConfirmation(false);
                          });
                        //   applicationService
                        //     .cancelApplication(selectedApplication.id)
                        //     .then((response) => {
                        //       console.log(response);
                        //       toast.success("Your application has been cancelled!");
                        //       setTimeout(() => {
                        //         setLoading(false);
                        //         getApplication();
                        //         setShowCancellationConfirmation(false);
                        //       }, 3000);
                        //     })
                        //     .catch((err) => {
                        //       console.log(err);
                        //       toast.error("An error occurred while updating your product. Please try again.");
                        //       setLoading(false);
                        //     });
                      })}
                    </motion.div>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )
        //    : error ? (
        //     <div className="flex  items-center justify-center">
        //       <h3 className={`mt-10`}>We could not retrieve this application at this time. Please try again later.</h3>
        //     </div>
        //   ) : (
        //     <div className={` flex flex-row items-center justify-center min-h-full `}>
        //       {loading ? (
        //         // Loading component with fade-in effect
        //         <div
        //           className={`flex flex-col h-full justify-center  items-center  w-full mx-auto  font-montserrat  transition-opacity duration-500 `}
        //           style={{ animation: "fadeIn 0.5s forwards" }}
        //         >
        //           <CircularProgress size={60} style={{ color: "#86eb85" }} />
        //           <h3 className="mt-10">Retrieving your application...</h3>
        //         </div>
        //       ) : show2FAForm ? (
        //         <div
        //           className={`w-full justify-center flex flex-col items-center mx-auto max-w-[600px] font-montserrat mt-10 transition-opacity duration-500 ${
        //             loading ? `opacity-0 invisible` : `opacity-100 visible`
        //           }`}
        //           style={{ transition: "visibility 0s, opacity 0.5s linear" }}
        //         >
        //           <h1>VERIFICATION</h1>
        //           <h3>We have sent you an SMS with a verification code. Please enter it below to continue.</h3>
        //           <div className="flex flex-col items-start w-full">
        //             <p className="text-base text-primary font-medium mt-2 mb-1">SMS verification code</p>
        //             <input
        //               type="text"
        //               name="twoFACode"
        //               placeholder="Code"
        //               value={formData.twoFACode}
        //               onChange={handleChange}
        //               required
        //               className={`p-2 border-2 mb-1 rounded-md w-full ${
        //                 formErrors.twoFACode ? "border-red bg-red/10" : "border-primary/80"
        //               }`}
        //             />

        //             {formErrors.twoFACode && <span className="text-red text-left w-full">{formErrors.twoFACode}</span>}
        //             {formErrors.reqError && <span className="text-red text-right w-full mt-5">{formErrors.reqError}</span>}

        //             <button
        //               onClick={() => {
        //                 verify2FACode();
        //               }}
        //               className="text-secondary md:ml-auto font-bold bg-primary py-2 px-4 rounded rounded-4 mt-5 transform transition-transform duration-100 font-montserrat hover:scale-105"
        //               disabled={loading}
        //             >
        //               CONTINUE
        //             </button>
        //           </div>
        //         </div>
        //       ) : (
        //         <div
        //           className={`w-full justify-center flex flex-col items-center mx-auto max-w-[600px] font-montserrat mt-10 transition-opacity duration-500 ${
        //             loading ? `opacity-0 invisible` : `opacity-100 visible`
        //           }`}
        //           style={{ transition: "visibility 0s, opacity 0.5s linear" }}
        //         >
        //           <h1>VERIFICATION</h1>
        //           <h3>Please enter your details to view your application</h3>
        //           <div className="flex flex-col items-start w-full">
        //             <p className="text-base text-primary font-medium mt-2 mb-1">Last Name</p>
        //             <input
        //               type="text"
        //               name="surname"
        //               placeholder="Last Name"
        //               value={formData.surname}
        //               onChange={handleChange}
        //               required
        //               className={`p-2 border-2 mb-1 rounded-md w-full ${
        //                 formErrors.surname ? "border-red bg-red/10" : "border-primary/80"
        //               }`}
        //             />

        //             {formErrors.surname && <span className="text-red text-left w-full">{formErrors.surname}</span>}
        //             <p className="text-base text-primary font-medium mt-2 mb-1">Email</p>

        //             <input
        //               type="text"
        //               name="email"
        //               placeholder="Email address"
        //               value={formData.email}
        //               onChange={handleChange}
        //               required
        //               className={`p-2 border-2 mb-1 rounded-md w-full ${
        //                 formErrors.email ? "border-red bg-red/10" : "border-primary/80"
        //               }`}
        //             />
        //             {formErrors.email && <span className="text-red text-left w-full">{formErrors.email}</span>}

        //             {formErrors.reqError && <span className="text-red text-right w-full mt-5">{formErrors.reqError}</span>}

        //             <button
        //               onClick={() => {
        //                 get2FAToken();
        //               }}
        //               className="text-secondary md:ml-auto font-bold bg-primary py-2 px-4 rounded rounded-4 mt-5 transform transition-transform duration-100 font-montserrat hover:scale-105"
        //               disabled={loading}
        //             >
        //               CONTINUE
        //             </button>
        //           </div>
        //           {/* <TextField

        //     label="Surname"
        //     variant="outlined"
        //     value={surname}
        //     onChange={(e) => setSurname(e.target.value)}
        //     className="mt-4"
        //   />{" "} */}
        //         </div>
        //       )}
        //     </div>
        //   )
      }
    </div>
  );
};

export default Application;
